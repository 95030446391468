.teaser-grid {
  position: relative;
}

.teaser-grid__teasers {
  @include use-responsive-sizing(gap, $gaps);

  display: grid;
  //grid-auto-rows: 1fr;
  grid-template-columns: repeat(var(--teaser-grid-columns), minmax(0, 1fr));

  .teaser-grid--3 & {
    @include use-responsive-sizing(--teaser-grid-columns, (
      xs: 1,
      m: 2,
      l: 3,
    ));
  }

  .teaser-grid--4 & {
    @include use-responsive-sizing(--teaser-grid-columns, (
      xs: 1,
      m: 2,
      xl: 4,
    ));
  }

  .teaser-grid--5 & {
    @include use-responsive-sizing(--teaser-grid-columns, (
      xs: 1,
      m: 2,
      l: 5,
    ));
  }

  .teaser-grid--6 & {
    @include use-responsive-sizing(--teaser-grid-columns, (
      xs: 2,
      m: 3,
      l: 6,
    ));

    grid-auto-rows: auto;
  }
}

.teaser-grid__teaser {
  align-items: stretch;
  display: flex;
}
