.alert {
  border: 1px solid currentColor;
  padding: 2rem;
}

.alert--success {
  background-color: $color-sand;
  color: $color-green;
}

.alert--error {
  background-color: rgba($color-red, 0.02);
  color: $color-red;
}
