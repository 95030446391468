.events-teaser__sheet {
  background-color: $color-green;
  color: $color-white;
  font-weight: bold;
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
  text-align: center;
  width: 8rem;
  border-radius: 1rem;
}

.events-teaser__sheet-day {
  display: block;
  font-family: $font-stack-headline;
  font-size: 3.2rem;
  margin-bottom: 0.8rem;
}

.events-teaser__sheet-month {
  font-size: 2rem;
}

.events-teaser__events {
  margin-top: 2.4rem;
}

.events-teaser__event {
  --link-color: #{$text-color};
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
  hyphens: auto;
  line-height: $line-height-body;

  & + & {
    margin-top: 1.6rem;
  }
}
