.icon-button {
  background-color: $color-green;
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.2rem $color-green;
  color: $color-white;
  display: inline-block;
  font-size: 1.8rem;
  height: 3.8rem;
  line-height: 0;
  padding: 1rem;
  transition-property: background-color, box-shadow, color, opacity;
  user-select: none;
  width: 3.8rem;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: $color-white;
    color: $color-green;
  }

  @include mq($from: m) {
    font-size: 2rem;
    height: 4.2rem;
    padding: 1.1rem;
    width: 4.2rem;
  }
}
