.calendar {
  --fc-button-bg-color: #{$color-green};
  --fc-button-border-color: #{$color-green};
  --fc-button-hover-bg-color: #{$color-green-alt-1};
  --fc-button-hover-border-color: #{$color-green-alt-1};
  --fc-button-active-bg-color: #{$color-black};
  --fc-button-active-border-color: #{$color-black};
  --fc-event-bg-color: #{$color-green};
  --fc-event-border-color: #{$color-green};

  .fc-h-event:hover,
  .fc-h-event:focus {
    --fc-event-bg-color: #{$color-green-alt-1};
  }

  .fc-header-toolbar {
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
  }

  .fc-toolbar-chunk {
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 100%;

    @include mq($from: l) {
      width: auto;
    }
  }

  .fc-toolbar-title {
    color: $color-green;
    font-family: $font-stack-headline;
    font-weight: bold;
    line-height: $line-height-headline;
    text-transform: uppercase;
  }
}

.calendar__subscribe {
  margin-top: 3.2rem;
  text-align: right;
}
