.teaser-slider {
  position: relative;
}

.teaser-slider__inner {
  @include use-break-out();

  margin-bottom: -2.4rem;
  margin-top: -2.4rem;
}

.teaser-slider__teasers {
  @include use-responsive-sizing(scroll-padding-left scroll-padding-right, $inner-container-padding);

  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  z-index: 1;

  .js & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .js &::-webkit-scrollbar {
    display: none;
  }

  @include mq($from: rem2px($inner-container-max-width)) {
    scroll-padding-left: calc((100% - #{$inner-container-max-width}) / 2 + 7.2rem);
    scroll-padding-right: calc((100% - #{$inner-container-max-width}) / 2 + 7.2rem);
  }
}

.teaser-slider__spacer {
  @include use-responsive-sizing(width, $inner-container-padding);

  flex-grow: 1;
  flex-shrink: 0;

  @include mq($from: rem2px($inner-container-max-width)) {
    width: calc((100% - #{$inner-container-max-width}) / 2 + #{$inner-container-max-padding});
  }
}

.teaser-slider__teaser {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  scroll-snap-align: start;

  & + & {
    @include use-responsive-sizing(margin-left, $gaps);
  }
}

.teaser-slider--3 .teaser-slider__teaser {
  @include use-responsive-sizing(width, (
    xs: column-width(xs, 11, $with-spacer: true),
    s: column-width(s, 8, $with-spacer: true),
    m: column-width(m, 5, $with-spacer: true),
    l: column-width(l, 5, $with-spacer: true),
    xl: column-width(xl, 4, $with-spacer: true),
  ));

  @include mq($from: rem2px($inner-container-max-width)) {
    width: column-width(xl, 4, $base: ($inner-container-max-width - $inner-container-max-padding * 2));
  }
}

.teaser-slider--4 .teaser-slider__teaser {
  @include use-responsive-sizing(width, (
    xs: column-width(xs, 11, $with-spacer: true),
    s: column-width(s, 8, $with-spacer: true),
    m: column-width(m, 4, $with-spacer: true),
    l: column-width(l, 4, $with-spacer: true),
    xl: column-width(xl, 3, $with-spacer: true),
  ));

  @include mq($from: rem2px($inner-container-max-width)) {
    width: column-width(xl, 3, $base: ($inner-container-max-width - $inner-container-max-padding * 2));
  }
}

.teaser-slider__navigation {
  @include use-container();

  font-size: 0;
  padding: 2.4rem 0;
  text-align: center;
  user-select: none;

  .no-js & {
    display: none;
  }

  @include mq($from: s) {
    padding: 0;
  }
}

.teaser-slider__button {
  background-color: $color-gray;
  border-radius: 50%;
  box-shadow: 0 0 0 0.5rem $color-white;
  color: #fff;
  display: inline-block;
  font-size: 2.8rem;
  height: 4.8rem;
  line-height: 0;
  padding: 1rem;
  transition-property: background-color, opacity;
  user-select: none;
  width: 4.8rem;
  z-index: 3;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;

    @include mq($from: s) {
      opacity: 0;
    }
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: $color-black;
  }

  & + & {
    margin-left: 1.6rem;
  }

  @include mq($from: s) {
    opacity: 0;
    position: absolute;
    top: 50%;

    & + & {
      margin-left: 0;
    }

    .teaser-slider:hover &:not([disabled]) {
      opacity: 1;
    }
  }
}

@include mq($from: s) {
  .teaser-slider__button--left {
    left: 0;
    transform: translate(-50%, -50%);
  }

  .teaser-slider__button--right {
    right: 0;
    transform: translate(50%, -50%);
  }
}
