@keyframes fade-in-menu-search {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-overlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header {
  background-color: $color-white;
  box-shadow: 0 0.2px 0.7px rgba(0, 0, 0, 0.021), 0 0.6px 1.9px rgba(0, 0, 0, 0.03), 0 1.5px 4.5px rgba(0, 0, 0, 0.039), 0 5px 15px rgba(0, 0, 0, 0.06);
  padding-bottom: 1.6rem;
  padding-top: 1.6rem;
  position: sticky;
  top: 0;
  z-index: z-index('header');
}

.header__inner {
  @include use-container();

  display: flex;

  @include mq($from: l) {
    justify-content: space-between;
  }

 /* @include mq($from: xl) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }*/
}

.header__logo {
  align-items: center;
  display: inline-flex;
}

.header__logo-img {
  height: 4.2rem;
  width:auto;
}

.header__logo-text {
  --underline-focus-color: #{$color-green};

  color: $color-green;
  font-family: $font-stack-headline;
  font-size: 2.5rem;
  font-weight: bold;
  margin-left: 1.2rem;
  text-transform: uppercase;
}

.header__logo-text--long{
  font-size:2rem;
  line-height: 2.1rem;
}

.header__button {
  box-shadow: inset 0 0 0 1px $color-green;
  color: $color-green;
  cursor: pointer;
  font-size: 2.4rem;
  height: 4rem;
  line-height: 0;
  padding: 0.8rem;
  transition-property: background-color, color;
  width: 4rem;

  &[aria-expanded='true'],
  &:hover,
  &:not(.has-invisible-focus):focus {
    background-color: $color-green;
    color: $color-white;
  }
}

.header__navigation-button {
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 1.6rem;

  @include mq($from: l) {
    display: none;
  }
}

.header__navigation {
  animation: fade-in-overlay 0.2s;
  background-color: $color-white;
  display: none;
  inset: 0;
  padding: 2.4rem 8.8rem 2.4rem 2.4rem;
  position: fixed;
  z-index: z-index('menu');

  @include mq($from: l) {
    animation: none;
    display: flex;
    padding-bottom: 0;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 0;
    position: relative;
  }
}

.header__navigation--open {
  display: flex;
}

.header__navigation-close {
  inset: 2.4rem 2.4rem auto auto;
  position: absolute;

  @include mq($from: l) {
    display: none;
  }
}

.header__navigation-items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: none;
  overflow-y: auto;

  @include mq($from: l) {
    flex-direction: row;
  }
}

.header__navigation-item {
  --link-color: #{$text-color};
  --link-hover-color: #{$color-green};
  --focus-outline-offset: -3px;

  @include use-responsive-sizing(font-size, (
    xs: 1.9rem,
    l: 1.9rem,
    xl: 2rem,
  ));

  display: block;
  font-weight: bold;
  line-height: 2.1rem;

  & + & {
    margin-top: 0.4rem;

    @include mq($from: l) {
      margin-left: 0;
      margin-top: 0;
    }

    @include mq($from: xl) {
      margin-left: 0.8rem;
    }
  }
}

.header__navigation-menu-link,
.header__navigation-link {
  display: block;
  padding: 1rem 1.2rem;
  text-align: left;
  width: 100%;

  @include mq($from: l) {
    width: auto;
  }
}

.header__navigation-link--highlight {
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};
  --focus-outline-offset: -4px;
  --focus-outline-color: #{$color-white};

  background-color: $color-green;
  margin-top: 0.4rem;
  transition-property: background-color, color;

  &:hover,
  &:focus {
    background-color: $color-green-alt-1;
  }

  @include mq($from: l) {
    margin-left: 0.8rem;
    margin-top: 0;
  }

  @include mq($from: xl) {
    margin-left: 1.2rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.header__navigation-menu {
  padding: 0.4rem 0;

  @include mq($from: l) {
    animation: fade-in-menu-search 0.3s;
    background-color: $color-white;
    box-shadow: 0 0.2px 0.7px rgba(0, 0, 0, 0.021), 0 0.6px 1.9px rgba(0, 0, 0, 0.03), 0 1.5px 4.5px rgba(0, 0, 0, 0.039), 0 5px 15px rgba(0, 0, 0, 0.06);
    margin-left: -1.6rem;
    margin-top: 1.6rem;
    padding: 1.2rem 1.6rem;
    position: absolute;
    width: 26rem;
    z-index: z-index('menu');

    &::before {
      background-color: $color-white;
      content: '';
      height: 1rem;
      inset: -1rem -1rem 0;
      position: absolute;
    }
  }
}

.header__navigation-menu-item {
  display: block;
  font-size: 1.8rem;
  font-weight: normal;

  & + & {
    margin-top: 0.2rem;
  }
}

.header__highlight {
  align-items: flex-start;
  display: none;
  @include mq($from: l) {
    display: flex;
  }
  @include use-responsive-sizing(font-size, (
    xs: 1.9rem,
    l: 1.9rem,
    xl: 2rem,
  ));
  font-weight: bold;
  line-height: 2.1rem;
  text-align: right;
}
.header__search {
  flex-shrink: 0;
  display: flex;
  margin-left: 0.8rem;
  position: relative;
  text-align: right;

  @include mq($from: l) {
    margin-left: 0;
  }
}
.header__search-form {
  align-items: center;
  animation: fade-in-menu-search 0.3s;
  background-color: $color-white;
  box-shadow: 0 0.2px 0.7px rgba(0, 0, 0, 0.021), 0 0.6px 1.9px rgba(0, 0, 0, 0.03), 0 1.5px 4.5px rgba(0, 0, 0, 0.039), 0 5px 15px rgba(0, 0, 0, 0.06);
  inset: 100% -2.4rem auto auto;
  margin-top: 1.6rem;
  max-width: 40rem;
  padding: 1.6rem 2.4rem;
  position: absolute;
  width: 95vw;
  z-index: z-index('search');

  &:not([hidden]) {
    display: flex;
  }

  &::before {
    background-color: $color-white;
    content: '';
    height: 1rem;
    inset: -1rem -1rem 0;
    position: absolute;
  }
}

.header__search-form-button {
  flex-shrink: 0;
  margin-left: 2.4rem;
}
