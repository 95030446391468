.list {
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 6.4rem,
    m: 8rem,
    l: 8.8rem,
    xl: 9.6rem,
  ));
}

.list__inner {
  @include use-container();
}

.list__teasers,
.list__pagination {
  @include use-responsive-sizing(width, (
    xs: 100%,
    l: column-width(l, 10),
    xl: column-width(xl, 10),
  ));

  margin-left: auto;
  margin-right: auto;
}

.list__teaser {
  border-bottom: 1px solid $color-light-gray;
  border-top: 1px solid $color-light-gray;
  padding-bottom: 3.2rem;
  padding-top: 3.2rem;

  & + & {
    border-top: 0;
  }
}

.list__pagination {
  margin-top: 4rem;
}
