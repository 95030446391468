.search-header {
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 6.4rem,
    m: 8rem,
    l: 8.8rem,
    xl: 9.6rem,
  ));

  border-bottom: 3px solid $color-green;
}

.search-header__inner {
  @include use-container();
}

.search-header__headline,
.search-header__form {
  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: column-width(l, 10),
    xl: column-width(xl, 8),
  ));

  margin-left: auto;
  margin-right: auto;
}

.search-header__headline {
  color: $color-green;
  margin-bottom: 3.2rem;
  text-align: center;
}

.search-header__form {
  align-items: center;
  display: flex;
}

.search-header__form-input {
  flex-grow: 1;
}

.search-header__form-button {
  flex-shrink: 0;
  margin-left: 2.4rem;
}
