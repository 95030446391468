.breadcrumb {
  --underline-focus-color: #{$text-color};

  color: $text-color;
  font-size: 1.4rem;
  line-height: $line-height-body;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
}

.breadcrumb__items {
  @include use-container();

  display: block;
}

.breadcrumb__item {
  display: inline;
}

.breadcrumb__item--last {
  font-weight: bold;
}

.breadcrumb__icon {
  font-size: 0.9rem;
  line-height: 0;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}
