.u-hidden-visually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.u-skip-link {
  background-color: $color-green;
  color: $color-white;
  font-size: 2rem;
  font-weight: bold;
  left: 0;
  outline: 0;
  padding: 1.6rem 3.2rem;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transform: translateY(-500%);
  z-index: z-index('skip-link');

  &:hover,
  &:focus,
  &:active {
    transform: translateY(0);
  }
}

.u-overlay-link {
  &::after {
    bottom: 0;
    content: '';
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;
    z-index: 2;
  }

  &--with-content-links{
    &::after {
      content: none;
    }
  }
}

.u-overlay-link--padding {
  &::after {
    inset: -2rem;
  }
}

.u-underline,
.u-underline-focus {
  text-decoration-line: var(--underline-line, underline);
  text-decoration-skip: var(--underline-skip, objects);
  text-decoration-skip-ink: var(--underline-skip-ink, auto);
  text-decoration-style: solid;
  text-decoration-thickness: var(--underline-height, 2px);
  text-underline-offset: var(--underline-offset, 0.25em);
  transition-property: text-decoration-color;
}

.u-underline-focus {
  text-decoration-color: transparent;

  button:not([disabled]):hover &,
  button:not(.has-invisible-focus):focus &,
  :any-link:hover &,
  :any-link:not(.has-invisible-focus):focus &,
  .has-underline:hover &,
  .has-underline:not(.has-invisible-focus):focus &,
  .has-underline:not(.has-invisible-focus):focus-within & {
    text-decoration-color: var(--underline-focus-color, currentColor);
  }
}

.u-underline {
  text-decoration-color: var(--underline-color, currentColor);

  button:not([disabled]):hover &,
  button:not(.has-invisible-focus):focus &,
  :any-link:hover &,
  :any-link:not(.has-invisible-focus):focus &,
  .has-underline:hover &,
  .has-underline:not(.has-invisible-focus):focus &,
  .has-underline:not(.has-invisible-focus):focus-within & {
    text-decoration-color: transparent;
  }
}
