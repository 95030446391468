.copyright {
  --underline-height: 1px;
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  background-color: rgba(0, 0, 0, 0.55);
  color: $color-white;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1rem;
  max-width: 100%;
  padding: 0.2rem 0.5rem 0.3rem;
  pointer-events: all;
  text-align: right;
}
