.stage {
  position: relative;
}

.stage__track {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
}

.stage__slide {
  flex-shrink: 0;
  position: relative;
  width: 100%;
}

.stage__image {
  @include use-responsive-sizing(height, (
    xs: 50rem,
    m: 55rem,
    l: 60rem,
    xl: 65rem,
  ));

  display: flex;
  flex-direction: column;
}

.stage__content {
  inset: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.stage__content-inner {
  @include use-container();
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 6rem,
    m: 7rem,
    l: 10rem,
  ));

  align-items: center;
  color: $color-white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  text-align: center;
}

.stage__headline,
.stage__text {
  pointer-events: all;
  text-shadow: 0 0 1rem rgba(#000, 0.8);
}

.stage__text {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: $line-height-body;
  margin-top: 1.2rem;

  @include mq($from: m) {
    font-size: 2.4rem;
  }
}

.stage__button {
  --focus-outline-color: #{$color-white};

  margin-top: 3.2rem;
  pointer-events: all;
}

.stage__navigation {
  inset: 0;
  pointer-events: none;
  position: absolute;
  z-index: 3;
}

.stage__navigation-inner {
  @include use-container();

  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.stage__navigation-indicators {
  align-self: flex-end;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2.4rem;
}

.stage__navigation-indicator {
  background-color: $color-white;
  border-radius: 50%;
  height: 1.3rem;
  opacity: 0.3;
  pointer-events: all;
  transition-property: opacity;
  width: 1.3rem;

  &[disabled] {
    pointer-events: none;
  }

  &[disabled],
  &:hover,
  &:focus {
    opacity: 1;
    outline-color: $color-white;
  }

  & + & {
    margin-left: 1.3rem;
  }
}

.stage__navigation-button {
  background-color: $color-gray;
  border-radius: 50%;
  box-shadow: 0 0 0 0.5rem $color-white;
  color: #fff;
  display: none;
  font-size: 2.8rem;
  height: 4.8rem;
  line-height: 0;
  padding: 1rem;
  position: relative;
  transition-property: background-color, opacity;
  user-select: none;
  width: 4.8rem;
  z-index: 3;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;

    @include mq($from: s) {
      opacity: 0;
    }
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: $color-black;
  }

  & + & {
    margin-left: 1.6rem;
  }

  @include mq($from: s) {
    display: block;
    opacity: 0;

    & + & {
      margin-left: 0;
    }

    .stage:hover &:not([disabled]) {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.stage__navigation-button--left {
  transform: translateX(-50%);
}

.stage__navigation-button--right {
  transform: translateX(50%);
}
