.preview {
  min-height: 100vh;
  padding: 2rem;
}

.preview--paddingless {
  padding: 0;
}

.preview--green {
  background-color: $color-green;
}
