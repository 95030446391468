.quote__quote {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.1rem, xl 2.6rem));

  color: $text-color;
  display: block;
  font-size: 2.2rem;
  line-height: $line-height-body;
  quotes: '„' '“' '‚' '‘';

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

.quote__caption {
  color: $text-color;
  display: block;
  font-size: 2rem;
  line-height: $line-height-body;
  margin-bottom: 1.2rem;
}

