.section {
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 6.4rem,
    m: 8rem,
    l: 8.8rem,
    xl: 9.6rem,
  ));

  position: relative;

  & + &,
  .content + & {
    padding-top: 0;
  }

  &-bg--green{
    background-color: #{$color-green};
  }

  &-bg--sand{
    background-color: #{$color-sand};
  }

  &-bg--green-alt-1{
    background-color: #{$color-sand};
  }

  &-bg--green-alt-2{
    background-color: #{$color-sand};
  }

  &-bg--blue{
    background-color: #{$color-sand};
  }


  &-bg--yellow{
    background-color: #{$color-yellow};
  }
}

.section__inner {
  @include use-container();
}

.section__text {
  font-size: 1.8rem;
  line-height: $line-height-body;
  max-width: 60rem;

  > * + * {
    margin-top: 1.6rem;
  }

  @include mq($from: m) {
    font-size: 2rem;
  }
}

.section__headline {
  --headline-color: #{$color-green};
}

.section__text:not(:first-child) {
  margin-top: 1.6rem;
}

.section__content:not(:first-child) {
  margin-top: 3.2rem;
}

.section__button:not(:first-child) {
  margin-top: 4rem;
}

.section__button {
  text-align: center;
}


