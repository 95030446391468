.portal-header {
  background-color: $color-green;
  position: relative;
}

.portal-header__image {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: 1;
}

.portal-header__inner {
  @include use-container();

  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 6.4rem,
    m: 8rem,
    l: 8.8rem,
    xl: 9.6rem,
  ));

  position: relative;
  z-index: 2;

  .portal-header--with-image & {
    @include use-responsive-sizing(min-height, (
      xs: 35rem,
      m: 40rem,
      l: 45rem,
    ));

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.portal-header__headline {
  --headline-kicker-color: #{$color-white};
  --headline-kicker-margin: 1.6rem;
  --headline-kicker-font-weight: bold;

  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: column-width(l, 10),
    xl: column-width(xl, 8),
  ));

  color: $color-white;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .portal-header--with-image & {
    text-shadow: 0 0 1rem rgba(#000, 0.8);
  }

  @include mq($from: m) {
    --headline-kicker-font-size: 1.8rem;
  }
}
