.collapse-text__summary {
  background-color: $color-sand;
  color: $color-green;
  cursor: pointer;
  font-weight: bold;
  line-height: $line-height-body;
  list-style: none;
  padding: 1.2rem 2.4rem;
  border-radius: 1rem 1rem 0 0;

  &::-webkit-details-marker {
    display: none;
  }

  &::marker {
    display: none;
  }
}

.collapse-text__summary-icon-text {
  display: flex;
}

.collapse-text__summary-icon {
  flex-shrink: 0;
  transition-property: transform;

  .collapse-text[open] & {
    transform: rotate(180deg) translateY(-10%);
  }
}

.collapse-text__summary-text {
  --underline-focus-color: #{$color-green};

  align-self: center;
  margin-left: 1.2rem;

  .collapse-text__summary:focus:not(:focus-visible):not(:hover) & {
    --underline-focus-color: transparent;
  }
}

.collapse-text__content {
  border: 1px solid $color-sand;
  padding: 2.4rem;
  border-radius: 0 0 1rem 1rem;

  > * + * {
    margin-top: 1.6rem;

    @include mq($from: m) {
      margin-top: 1.8rem;
    }
  }

  > * + .headline {
    margin-top: 3.2rem;

    @include mq($from: m) {
      margin-top: 4rem;
    }
  }
}
