.teaser {
  flex-grow: 1;
  position: relative;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;


  &:focus-within {
    @include use-outline();
  }
}

.teaser--green {
  --underline-focus-color: #{$color-white};
  --link-color:#{$color-white};
  --link-hover-color:#{$color-white};
  background-color: $color-green;
  color: $color-white;

  .teaser__button{
    --button-background-color: #{$color-white};
    --button-color: #{$color-green};
    --button-focus-background-color: #{$color-green};
    --button-focus-color: #{$color-white};
  }

}


.teaser--green-alt-1 {
  --underline-focus-color: #{$color-white};
  --link-color:#{$color-white};
  --link-hover-color:#{$color-white};
  background-color: $color-green-alt-1;
  color: $color-white;

  .teaser__button{
    --button-background-color: #{$color-white};
    --button-color: #{$color-green-alt-1};
    --button-focus-background-color: #{$color-green-alt-1};
    --button-focus-color: #{$color-white};
  }

}

.teaser--green-alt-2 {
  --underline-focus-color: #{$color-green};
  --link-color:#{$color-green};
  --link-hover-color:#{$color-green};
  background-color: $color-green-alt-2;
  color: $color-green;

  .teaser__button{
    --button-background-color: #{$color-green};
    --button-color: #{$color-white};
    --button-focus-background-color: #{$color-green-alt-2};
    --button-focus-color: #{$color-green};
  }

}

.teaser--blue {
  --underline-focus-color: #{$color-white};
  background-color: $color-blue;
  color: $color-white;
  --link-color:#{$color-white};
  --link-hover-color:#{$color-white};

  .teaser__button{
    --button-background-color: #{$color-white};
    --button-color: #{$color-blue};
    --button-focus-background-color: #{$color-blue};
    --button-focus-color: #{$color-white};
  }
}

.teaser--yellow {
  --underline-focus-color: #{$color-green};
  --link-color:#{$color-green};
  --link-hover-color:#{$color-green};
  background-color: $color-yellow;
  color: $color-green;

  .teaser__description{
    color: $color-black;
  }

  .teaser__button{
    --button-background-color: #{$color-green};
    --button-color: #{$color-white};
    --button-focus-background-color: #{$color-yellow};
    --button-focus-color: #{$color-green};
  }

}

.teaser--sand {
  --underline-focus-color: #{$color-green};
  --link-color:#{$color-green};
  --link-hover-color:#{$color-green};
  background-color: $color-sand;
  color: $color-green;

  .teaser__description{
    color: $color-black;
  }

  .teaser__button{
    --button-background-color: #{$color-green};
    --button-color: #{$color-white};
    --button-focus-background-color: #{$color-sand};
    --button-focus-color: #{$color-green};
  }
}



.teaser--light-green {
  --underline-focus-color: #{$color-black};
  background-color: $color-light-green;
  color: $color-black;
}

.teaser--pink {
  --underline-focus-color: #{$color-white};
  background-color: $color-pink;
  color: $color-white;

  .teaser__button{
    --button-background-color: #{$color-white};
    --button-color: #{$color-black};
    --button-focus-background-color: #{$color-pink};
    --button-focus-color: #{$color-white};
  }

}

.teaser--light-pink {
  --underline-focus-color: #{$color-black};
  background-color: $color-light-pink;
  color: $color-black;
}

.teaser--light-blue {
  --underline-focus-color: #{$color-black};
  background-color: $color-light-blue;
  color: $color-black;
}

.teaser--light-yellow {
  --underline-focus-color: #{$color-black};
  background-color: $color-light-yellow;
  color: $color-black;
}

.teaser--gray {
  --underline-focus-color: #{$color-white};
  background-color: $color-gray;
  color: $color-white;
}

.teaser--light-gray {
  --underline-focus-color: #{$color-black};
  background-color: $color-light-gray;
  color: $color-black;
}




.teaser__image {
  pointer-events: none;
  position: relative;
  z-index: 3;
}

.teaser__content {
  padding: 2.4rem 2.4rem 0;

  @include mq($from: l) {
    padding: 3.2rem 3.2rem 0;
  }
}

.teaser--transparent .teaser__content {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.teaser__headline {
  --focus-outline-width: 0;
  margin-bottom:1rem;
}

.teaser__description {
  font-size: 2rem;
  line-height: $line-height-body;
  margin: 1.6rem 0;

  > * + * {
    margin-top: 0.8rem;
  }

  @include mq($from: l) {
    font-size: 2rem;
  }
}

.teaser__button {
  margin: auto 2.4rem 2.4rem;

  @include mq($from: l) {
    margin: auto 2.4rem 2.4rem;
  }
}



.teaser__contact-details {
  margin-top:1.2rem;
  margin-bottom:  2.4rem;

  @include mq($from: l) {
    margin-top: 1.6rem;
    margin-bottom:  3.2rem;
  }

  @include use-responsive-sizing(font-size, responsive-map(xs 1.4rem, xl 2rem));

  line-height: $line-height-body;
}

.teaser__description + .teaser__contact-details{
  margin-top: 2.4rem;

  @include mq($from: l) {
    margin-top: 3.2rem;
  }
}

.teaser__contact-detail {
  --link-icon-margin: 1rem;

  & + & {
    margin-top: 0.8rem;
  }
}
