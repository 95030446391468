.event-box {
  border: 1px solid $color-green;
}

.event-box__details {
  padding: 2.4rem;
}

.event-box__row {
  display: flex;
  line-height: $line-height-body;

  & + & {
    margin-top: 0.4rem;
  }
}

.event-box__key {
  color: $color-green;
  flex-shrink: 0;
  margin-right: 1.2rem;
}

.event-box__download {
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  background-color: $color-green;
  padding: 1.2rem 2.4rem;
  text-align: right;
}
