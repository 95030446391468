.person__image {
  margin-bottom: 2.4rem;
  max-width: 54rem;

  @include mq($from: l) {
    margin-bottom: 3.2rem;
  }
}

.person__description {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.6rem, xl 2.4rem));

  line-height: $line-height-body;
  margin-top: 0.4rem;
}

.person__text,
.person__contact-details {
  margin-top: 2.4rem;

  @include mq($from: l) {
    margin-top: 3.2rem;
  }
}

.person__contact-details {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.4rem, xl 2rem));

  line-height: $line-height-body;
}

.person__contact-detail {
  --link-icon-margin: 1rem;

  & + & {
    margin-top: 0.8rem;
  }
}
