@import "photoswipe/dist/photoswipe.css";
@import "photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css";

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2.4rem;
  margin: 0;
  padding: 0;
  list-style: none;
  .image {
    margin: 0;
    padding: 0;
    display: inline-flex;
    aspect-ratio: 3/2;
    align-items: center;
    justify-content: center;
    border-radius:1rem;
    background: #{$color-sand};
    overflow:hidden;
    width:100%;
    .image__caption{
      display: none;
    }
  }
  img {
    object-fit: cover;
    width: auto;
    height: 100%;
    display: block;
  }
}

.pswp img {
  height:auto !important;
  display:block;
}
