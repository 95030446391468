@use 'sass:math';

// Colors
$color-black: #000;
$color-gray: #363636;
$color-light-gray: #f0f0f0;
$color-white: #fff;
$color-sand: #f5f1e9;
$color-green: #005437;
$color-green-alt-1: #008939;
$color-green-alt-2: #8abd24;
$color-light-green: #e5f6ee;
$color-pink: #ff495d;
$color-light-pink: #ffe4f3;
$color-blue: #0BA1dd;
$color-light-blue: #d4edfc;
$color-yellow: #fff17A;
$color-light-yellow: #fffddb;
$color-red: #f00;

// Text color
$text-color: $color-gray;
$input-border-color: #949494;

// Font families
$font-stack-body: 'PT Sans', Helvetica, Arial, sans-serif;
$font-stack-headline: 'GrueneType', Helvetica, Arial, sans-serif;

// Line height
$line-height-headline: 1.3;
$line-height-body: 1.7;

// Durations
$duration-default: 0.2s;
$duration-long: 0.4s;

// Z-Index
$z-index-list: 'flyout', 'skip-link', 'consent-banner', 'menu', 'search', 'header';

// Focus
$focus-outline-color: $color-gray;

// Root font size for conversion between px and rem values
$root-font-size-px: 10px;
$root-min-width: 320px;

// Columns
$columns: 12;

// Gap
$gaps: (
  xs: 2.4rem,
  s: 2.4rem,
  m: 2.4rem,
  l: 2.4rem,
  xl: 2.4rem,
);

// stylelint-disable length-zero-no-unit
$mq-breakpoints: (
  xs: 0px,
  s: 600px,
  m: 768px,
  l: 1024px,
  xl: 1440px,
);
// stylelint-enable

// Inner container
$inner-container-padding: (
  xs: 5vw,
  s: 5vw,
  m: 5vw,
  l: 5vw,
  xl: 5vw,
);

$inner-container-max-width: 144rem;
$inner-container-max-padding: math.div(map-get($inner-container-padding, xl), 100vw) * $inner-container-max-width;

// Exports
:export {
  l: map-get($mq-breakpoints, l);
  m: map-get($mq-breakpoints, m);
  s: map-get($mq-breakpoints, s);
  xl: map-get($mq-breakpoints, xl);
  xs: map-get($mq-breakpoints, xs);
}
