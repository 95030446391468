.breaker {
  background-color: $color-green;
  position: relative;
}

.breaker__image {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: 1;
}

.breaker__inner {
  @include use-container();

  @include use-responsive-sizing(min-height, (
    xs: 40rem,
    m: 45rem,
    l: 50rem,
  ));

  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 2rem,
    m: 3rem,
    l: 4rem,
  ));

  align-items: center;
  color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  z-index: 2;

  @include mq($from: m) {
    align-items: flex-start;
    text-align: left;
  }
}

.breaker__headline,
.breaker__text {
  max-width: 60rem;

  .breaker--with-image & {
    text-shadow: 0 0 1rem rgba(#000, 0.8);
  }
}

.breaker__text {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: $line-height-body;
  margin-top: 1.2rem;

  @include mq($from: m) {
    font-size: 2rem;
  }
}

.breaker__button {
  --focus-outline-color: #{$color-white};

  margin-top: 3.2rem;
}

.breaker__button .button--white{
  --button-background-color: #{$color-white};
  --button-color: #{$color-green};
  --button-focus-background-color: #{$color-green};
  --button-focus-color: #{$color-white};
}
