.footer {
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  @include use-responsive-sizing(--footer-gap, $gaps);
  @include use-responsive-sizing(padding-top padding-bottom, responsive-map(xs 4.8rem, xl 6.4rem));

  background-color: $color-green;
  color: $color-white;
  line-height: $line-height-body;
}

.footer__inner {
  @include use-container();
}

.footer__links-social-media-accounts {
  display: flex;
  flex-direction: column;

  @include mq($from: m) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__link {
  --link-icon-margin: 1rem;
  --link-icon-size: 0.8em;
  --link-icon-margin-bottom: 0.4rem;

  @include use-responsive-sizing(font-size, (
    xs: 1.8rem,
    m: 2rem,
    l: 2.2rem,
    xl: 2.4rem,
  ));

  display: block;
  font-weight: bold;

  & + & {
    margin-top: 0.4rem;
  }
}

.footer__social-media-accounts {
  display: flex;
  margin-top: calc(var(--footer-gap) * 2);

  @include mq($from: m) {
    margin-top: 0;
  }
}

.footer__social-media-account {
  display: flex;

  & + & {
    margin-left: var(--footer-gap);
  }
}

.footer__social-media-account-button {
  @include use-responsive-sizing(--social-media-account-button-width, responsive-map(xs 4.8rem, xl 7.2rem));

  align-items: center;
  box-shadow: inset 0 0 0 1px $color-white;
  display: flex;
  font-size: calc(var(--social-media-account-button-width) * 0.45);
  height: var(--social-media-account-button-width);
  justify-content: center;
  line-height: 0;
  transition-property: background-color, color;
  width: var(--social-media-account-button-width);

  &:hover,
  &:focus {
    background-color: $color-white;
    color: $color-green;
  }
}

.footer__meta-links {
  @include use-responsive-sizing(margin-top, responsive-map(xs 4.8rem, xl 6.4rem));

  display: flex;
  flex-direction: column;

  @include mq($from: m) {
    flex-direction: row;
  }
}

.footer__meta-link {
  display: block;

  & + & {
    margin-top: var(--footer-gap);

    @include mq($from: m) {
      margin-left: var(--footer-gap);
      margin-top: 0;
    }
  }
}
