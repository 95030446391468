// Use border box sizing
// Default transition time
*,
::before,
::after {
  box-sizing: border-box;
  transition-duration: $duration-default;
  transition-property: none;
}

// Reset all elements
* {
  border: 0;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

// Defaults
html {
  cursor: default;
  line-height: 1;
  tab-size: 4;
  text-size-adjust: 100%;
}

// Responsive media embeds
iframe,
img,
video {
  height: auto;
  max-width: 100%;
}

// Match type styles of form elements to parents
input,
optgroup,
select,
textarea {
  appearance: none;
  border-radius: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;
  touch-action: manipulation;
}

// Default link style, style buttons as links
a,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  display: inline;
  outline: none;
  text-decoration: none;
  touch-action: manipulation;
  user-select: text;
}

// Remove the border and padding in all browsers (opinionated).
[type='color'],
[type='range'] {
  border-width: 0;
  padding: 0;
}

// Fix hr line
hr {
  height: 0;
  overflow: visible;
}

// Remove the border on iframes and images
iframe,
img {
  border-style: none;
}

// Collapse border spacing
table {
  border-collapse: collapse;
}

// Add the correct display
[hidden] {
  display: none;
}

// Reset bold
strong,
b {
  font-weight: bold;
}

// Reset italic
em,
i {
  font-style: italic;
}
