.instagram-gallery {
  position: relative;
}

.instagram-gallery__images {
  @include use-responsive-sizing(--instagram-gallery-columns, (
    xs: 1,
    m: 2,
    l: 3,
  ));

  @include use-responsive-sizing(gap, $gaps);

  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(var(--instagram-gallery-columns), minmax(0, 1fr));
}

.instagram-gallery__image {
  --figure-font-size: 1.6rem;

  position: relative;
}

.instagram-gallery__image-link {
  --link-color: #{rgba($color-gray, 0.8)};
  --link-hover-color: #{$color-gray};

  font-size: 1.4rem;
  margin-top: 0.8rem;
}

.craftagram-item{
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
