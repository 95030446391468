.list-teaser {
  @include use-responsive-sizing(--list-teaser-gap, $gaps);

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  &:focus-within {
    @include use-outline();
  }

  @include mq($from: m) {
    flex-direction: row;
  }
}

.list-teaser__image {
  flex-shrink: 0;
  margin-top:1.2rem;
  margin-bottom: 2.4rem;

  @include mq($from: m) {
    margin-bottom: 0;
    margin-right: var(--list-teaser-gap);
    max-width: 30rem;
    width: calc(50% - var(--list-teaser-gap));
  }
}

.list-teaser__content {
  flex-grow: 0;
}

.list-teaser__section {
  background-color: $color-green;
  color: $color-white;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  /*margin-bottom: 1.6rem;*/
  padding: 0.5rem 0.8rem;
  flex-grow: 0;
}

.list-teaser__meta{
  margin:1rem 0 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:1.4rem;
  .headline__kicker{
    margin:0;
  }
}

.list-teaser__headline {
  --underline-focus-color: #{$text-color};
  --focus-outline-width: 0;
}

.list-teaser__description {
  font-size: 1.6rem;
  line-height: $line-height-body;
  margin-top: 1.6rem;

  > * + * {
    margin-top: 0.8rem;
  }

  em {
    background-color: $color-green;
    color: $color-white;
    font-style: normal;
  }

  @include mq($from: l) {
    font-size: 1.8rem;
  }
}
