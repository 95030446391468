.article {
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 6.4rem,
    m: 8rem,
    l: 8.8rem,
    xl: 9.6rem,
  ));

  position: relative;
}

.article__header {
  @include use-container();
}

.article__header-inner {
  @include use-responsive-sizing(width, (
    l: column-width(l, 10),
    xl: column-width(xl, 8),
  ));

  @include use-responsive-sizing(padding-bottom, (
    xs: 4.8rem,
    s: 5.2rem,
    m: 5.6rem,
    l: 6.4rem,
    xl: 8rem,
  ));

  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.article__headline {
  --headline-kicker-color: #{$text-color};
  --headline-kicker-margin: 1.6rem;
  --headline-kicker-font-weight: bold;

  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: 100%,
  ));

  color: $color-green;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @include mq($from: m) {
    --headline-kicker-font-size: 1.8rem;
  }
}

.article__teaser-text {
  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 8),
    l: column-width(l, 8, $number-of-columns: 10),
    xl: column-width(xl, 6, $number-of-columns: 8),
  ));

  @include use-responsive-sizing(font-size, (
    xs: 1.8rem,
    l: 2rem,
  ));

  color: $color-black;
  line-height: $line-height-body;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.2em;
  text-align: center;
}

.article__image {
  @include use-responsive-sizing(margin-top, (
    xs: 3.2rem,
    m: 4rem,
    l: 4.8rem,
  ));
}

.article__content {
  border-top: 3px solid $color-green;
}
