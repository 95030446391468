@font-face {
  font-display: swap;
  font-family: 'GrueneType';
  font-weight: bold;
  src: url('../../fonts/gruene-type.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'PT Sans';
  font-weight: normal;
  src: url('../../fonts/pt-sans_regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'PT Sans';
  font-weight: bold;
  src: url('../../fonts/pt-sans_bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'PT Sans';
  font-style: italic;
  src: url('../../fonts/pt-sans_italic.woff2') format('woff2');
}
