.headline {
  --underline-offset: 0.17em;

  color: var(--headline-color, currentColor);
  font-family: $font-stack-headline;
  font-weight: bold;
  line-height: $line-height-headline;
}

.headline--1 {
  @include use-responsive-sizing(font-size, responsive-map(xs 4.2rem, xl 6.8rem));
}

.headline--2 {
  @include use-responsive-sizing(font-size, responsive-map(xs 3.2rem, xl 5.6rem));
}

.headline--3 {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.8rem, xl 4.8rem));
}

.headline--4 {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.4rem, xl 3.2rem));
}

.headline--5 {
  font-size: 2rem;
}

.headline__link {
  display: block;
}

.headline__kicker {
  color: var(--headline-kicker-color, currentColor);
  display: block;
  font-family: $font-stack-body;
  font-size: var(--headline-kicker-font-size, 1.6rem);
  font-weight: var(--headline-kicker-font-weight, normal);
  line-height: $line-height-body;
  margin-bottom: var(--headline-kicker-margin, 0.4rem);
  text-transform: none;
}
