.tweet {
  --focus-outline-color: #{$color-white};

  background-color: $color-green;
  color: $color-white;
  flex-grow: 1;
  line-height: $line-height-body;
  padding: 2.4rem;
  position: relative;

  @include mq($from: l) {
    padding: 3.2rem;
  }
}

.tweet--inline {
  padding: 2rem;
}

.tweet__user {
  font-size: 1.6rem;
  padding-right: 3.2rem;
  position: relative;
}

.tweet__user-name {
  font-weight: bold;
}

.tweet__icon {
  font-size: 2rem;
  line-height: 2.2rem;
  position: absolute;
  right: 0;
  top: 0;
}

.tweet__text {
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  font-size: 1.6rem;
  margin-top: 1.6rem;

  @include mq($from: l) {
    font-size: 1.8rem;

    .tweet--inline & {
      font-size: 1.6rem;
    }
  }
}

.tweet__quote {
  border: 1px solid $color-white;
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;
}

.tweet__link {
  --underline-focus-color: #{$color-white};

  display: block;
  font-size: 1.4rem;
  margin-top: 1.6rem;
}
