.progress {
  position: relative;
}

.progress__label {
  font-size: 1.6rem;
  line-height: $line-height-body;
  margin-bottom: 0.8rem;
}

.progress__progress {
  background-color: $color-green;
  border-radius: 0.4rem;
  height: 0.8rem;

  &::before {
    background-color: $color-light-green;
    border-radius: 0.4rem;
    content: '';
    height: 0.8rem;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  }
}
