@keyframes flyout-backdrop-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flyout-flyout-fly-in {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.flyout {
  inset: 0;
  position: fixed;
  z-index: z-index('flyout');

  &[aria-hidden='true'] {
    display: none;
  }
}

.flyout__backdrop {
  animation: flyout-backdrop-fade-in $duration-default;
  background-color: rgba(0, 0, 0, 0.8);
  inset: 0;
  position: fixed;
  z-index: 1;
}

.flyout__flyout {
  animation: flyout-flyout-fly-in $duration-default;
  background-color: $color-white;
  border-right: 0.5rem solid $color-green;
  bottom: 0;
  height: 100vh;
  left: 0;
  max-width: 130rem;
  position: fixed;
  top: 0;
  width: 95vw;
  z-index: 2;

  @include mq($from: s) {
    width: 90vw;
  }
}

.flyout__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flyout__close {
  padding: 2.4rem 5vw;
  text-align: right;

  @include mq($from: rem2px($inner-container-max-width)) {
    padding-left: $inner-container-max-padding;
    padding-right: $inner-container-max-padding;
  }
}

.flyout__content {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;

  &:focus {
    outline: 0;
  }
}

.flyout__content-inner {
  @include use-container(70rem);

  padding-bottom: 10rem;
}
