.page {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 200rem;
  min-height: 100%;
  position: relative;
}

.page__breadcrumb {
  border-top: 1px solid $color-light-gray;
  margin-top: auto;
}

.page__footer {
  margin-top: auto;

  .page__breadcrumb + & {
    margin-top: 0;
  }
}
