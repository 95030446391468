.table-of-contents {
  --link-color: #{$color-green};
  --link-hover-color: #{$color-black};

  background-color: $color-sand;
  line-height: $line-height-body;
  padding: 3.2rem;
  border-radius: 1rem;
}

.table-of-contents__headline {
  color: $color-black;
  margin-bottom: 1.6rem;
}

.table-of-contents__list {
  & & {
    margin-top: 0.6rem;
    padding-left: 2.4rem;
  }
}

.table-of-contents__link {
  display: block;

  & + & {
    margin-top: 0.6rem;
  }
}
