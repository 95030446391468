.u-text {
  --headline-color: #{$color-green};

  @include use-responsive-sizing(font-size, (
    xs: 1.6rem,
    m: 2rem,
  ));

  line-height: $line-height-body;

  > * + * {
    margin-top: 1.6rem;

    @include mq($from: m) {
      margin-top: 1.8rem;
    }
  }

  > * + .headline {
    margin-top: 3.2rem;

    @include mq($from: m) {
      margin-top: 4rem;
    }
  }

  > p,
  > ul li,
  > ol li {
    hyphens: auto;
  }

  > ul,
  > ol,
  > ul ul,
  > ol ol {
    padding-left: 3.2rem;
  }

  > ul,
  > ul ul {
    list-style-type: square;
  }

  > ul li,
  > ol li {
    padding-left: 1.2rem;
  }

  > ul li::marker,
  > ol li::marker {
    color: $color-green;
    font-weight: bold;
  }

  > details {
    margin-bottom: 3.2rem;
    margin-top: 3.2rem;
  }

  > details + details {
    margin-top: -1.6rem;
  }

  > table {
    border-collapse: collapse;
    margin-bottom: 3.2rem;
    margin-top: 3.2rem;
    width: 100%;
  }

  > table th,
  > table td {
    padding: 0.8rem 1.2rem;
    text-align: left;
    vertical-align: text-top;
  }

  > table th {
    background-color: $color-green;
    color: $color-white;
    font-weight: bold;
  }

  > table th .headline {
    color: $color-white;
  }

  > table tr:nth-child(even) {
    background-color: $color-sand;
  }

  > hr {
    @include use-responsive-sizing(margin-top margin-bottom, (
      xs: 4.8rem,
      s: 5.2rem,
      m: 5.6rem,
      l: 6.4rem,
      xl: 8rem,
    ));

    border-bottom: 3px solid $color-green;
    clear: both;
  }
}

::selection {
  background: $color-green-alt-1;
  color: $color-white;
}
