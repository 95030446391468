.figure {
  display: block;
}

.figure__caption {
  color: $text-color;
  font-size: var(--figure-font-size, 1.4rem);
  line-height: $line-height-body;
  margin-top: 1.2rem;
}
