.content {
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 4.8rem,
    s: 5.2rem,
    m: 5.6rem,
    l: 6.4rem,
    xl: 8rem,
  ));

  & + &,
  .section + & {
    padding-top: 0;
  }
}

.content__inner {
  @include use-container();
}

.content__headline {
  --headline-color: #{$color-green};
  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 6),
  ));
  display: flow-root;
  margin-left: auto;
  margin-right: auto;
}

.content__text {
  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 6),
  ));

  display: flow-root;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.8rem;
  line-height: $line-height-body;
  margin-bottom: 3.2rem;
  margin-top: 1.6rem;

  @include mq($from: m) {
    font-size: 1.8rem;
  }
}

.content__content {
  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 6),
  ));

  display: flow-root;
  margin-left: auto;
  margin-right: auto;

  > hr {
    @include use-break-out();
  }
}

.content__aside {
  clear: both;

  &:last-child {
    margin-bottom: 0;
  }
}

.content__aside--full {
  @include use-responsive-sizing(margin-left margin-right, (
    s: column-width(s, -1, $number-of-columns: 10),
    m: column-width(m, -1, $number-of-columns: 10),
    l: column-width(l, -1, $number-of-columns: 8),
    xl: column-width(xl, -1, $number-of-columns: 6),
  ));

  @include use-responsive-sizing(margin-top margin-bottom, (
    xs: 3.2rem,
    s: 4rem,
    m: 4.8rem,
    l: 5.6rem
  ));
}

.content__aside--left {
  @include use-responsive-sizing(margin-right, $gaps, s);

  @include use-responsive-sizing(width, (
    s: column-width(s, 6, $number-of-columns: 10),
    m: column-width(m, 6, $number-of-columns: 10),
    l: column-width(l, 5, $number-of-columns: 8),
    xl: column-width(xl, 4, $number-of-columns: 6),
  ));

  @include use-responsive-sizing(margin-left, (
    s: column-width(s, -1, $number-of-columns: 10),
    m: column-width(m, -1, $number-of-columns: 10),
    l: column-width(l, -1, $number-of-columns: 8),
    xl: column-width(xl, -1, $number-of-columns: 6),
  ));

  @include mq($from: s) {
    float: left;
  }
}

.content__aside--right {
  @include use-responsive-sizing(margin-left, $gaps, s);

  @include use-responsive-sizing(width, (
    s: column-width(s, 6, $number-of-columns: 10),
    m: column-width(m, 6, $number-of-columns: 10),
    l: column-width(l, 5, $number-of-columns: 8),
    xl: column-width(xl, 4, $number-of-columns: 6),
  ));

  @include use-responsive-sizing(margin-right, (
    s: column-width(s, -1, $number-of-columns: 10),
    m: column-width(m, -1, $number-of-columns: 10),
    l: column-width(l, -1, $number-of-columns: 8),
    xl: column-width(xl, -1, $number-of-columns: 6),
  ));

  @include mq($from: s) {
    float: right;
  }
}

.content__content .content__aside--left,
.content__content .content__aside--right {
  @include mq($from: s) {
    margin-bottom: 3.2rem;
    margin-top: 3.2rem;

    + * {
      margin-top: 3rem;
    }

    hr + & {
      margin-top: 0;
    }
  }
}
