.button {
  background-color: var(--button-background-color, #{$color-black});
  border-radius: 2.1rem;
  box-shadow: inset 0 0 0 0.2rem var(--button-background-color, #{$color-black});
  color: var(--button-color, #{$color-white});
  display: inline-block;
  font-size: 1.8rem;
  font-weight: bold;
  height: 3.8rem;
  line-height: 1;
  padding: 1.1rem 1.8rem;
  text-align: center;
  transition-property: background-color, color;
  user-select: none;

  &:not([disabled]):hover,
  &:focus,
  .has-button-focus:hover &:not([disabled]),
  .has-button-focus:focus-within & {
    background-color: var(--button-focus-background-color, #{$color-white});
    color: var(--button-focus-color, #{$color-black});
  }

  @include mq($from: m) {
    font-size: 1.9rem;
    height: 4.2rem;
    padding: 1.2rem 2.4rem;
  }
}

.button--white {
  --button-background-color: #{$color-white};
  --button-color: #{$color-black};
  --button-focus-background-color: #{$color-black};
  --button-focus-color: #{$color-white};
}

.button--green {
  --button-background-color: #{$color-green};
  --button-focus-color: #{$color-green};
}

.button--pink {
  --button-background-color: #{$color-pink};
  --button-focus-color: #{$color-pink};
}
