.link {
  --underline-color: var(--link-color, #{$color-green});
  --underline-focus-color: var(--link-hover-color, #{$text-color});

  color: var(--link-color, #{$color-green});
  transition-property: color;

  &:not([disabled]):hover,
  &:not(.has-invisible-focus):focus {
    color: var(--link-hover-color, #{$text-color});
  }
}

.link__icon {
  font-size: var(--link-icon-size, 1em);
  margin-left: 0.1em;
  margin-right: var(--link-icon-margin, 0.3em);
  margin-bottom: var(--link-icon-margin-bottom, 0);
}
