.image {
  display: block;
  flex-grow: 1;
  line-height: 0;
  position: relative;
}

.image__img {
  font-size: 1.4rem;
  height: auto;
  width: 100%;

  .image--cover & {
    height: 100%;
    inset: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
  .image--teaser & {
    border-radius: 1rem 1rem 0 0;
  }
}

.image__copyright {
  bottom: var(--image-copyright-bottom, 0);
  left: var(--image-copyright-left, auto);
  position: absolute;
  right: var(--image-copyright-right, 0);
  top: var(--image-copyright-top, auto);
  z-index: 2;
}
