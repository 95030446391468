$field-box-shadow-hover-default: 0 0 0.5rem 0 transparent;
$field-box-shadow-hover: 0 0 0.5rem 0 rgba($color-gray, 0.4);
$field-box-shadow-focus-default: 0 0 0 0.1rem transparent;
$field-box-shadow-focus: 0 0 0 0.1rem $color-gray;

.field {
  line-height: 1;
}

.field__box {
  position: relative;
}

.field__box-label {
  color: $color-gray;
  display: block;
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
}

.field__box-input {
  background-color: $color-white;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 0.1rem $input-border-color;
  display: block;
  font-size: 1.6rem;
  height: auto;
  line-height: 3rem;
  min-width: 100%;
  padding: 1.2rem 1.6rem;
  position: relative;
  transition-property: box-shadow;
  width: 100%;
  z-index: 2;

  &:hover {
    box-shadow: inset 0 0 0 0.1rem $color-gray;
  }

  &:focus {
    --focus-outline-offset: -1px;
    --focus-outline-color: #{$color-green};

    box-shadow: inset 0 0 0 0.1rem $color-gray;
  }

  &[aria-invalid='true'] {
    --focus-outline-offset: -1px;
    --focus-outline-color: #{$color-red};

    box-shadow: inset 0 0 0 0.1rem $color-red;
    color: $color-red;
  }

  &:invalid {
    box-shadow: inset 0 0 0 0.1rem $input-border-color;
  }
}

.field__box-input--select {
  cursor: pointer;
}

.field__box-input--color {
  cursor: pointer;
  height: 5.4rem;
  padding: 0.5rem;
}

.field__box-input--hidden {
  display: none;
}

.field__box-input--has-icon {
  padding-right: 2.4rem;
}

.field__box-option {
  &[disabled] {
    color: $color-gray;
    cursor: not-allowed;
  }
}

.field__box-icon {
  bottom: 1.8rem;
  font-size: 1.8rem;
  height: 1.8rem;
  line-height: 0;
  pointer-events: none;
  position: absolute;
  right: 1.6rem;
  width: 1.8rem;
  z-index: 3;
}

.field__limit {
  background-color: $color-black;
  border-radius: 1.2rem;
  color: $color-white;
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  margin-top: 1.2rem;
  opacity: 0.3;
  padding: 0.4rem 1rem;
  transition-property: opacity;

  .field:focus-within & {
    opacity: 1;
  }
}

.field__legend {
  color: $color-gray;
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
}

.field__line {
  cursor: pointer;
  display: flex;
  user-select: none;

  &:focus-within {
    @include use-outline();
  }

  & + & {
    margin-top: 0.8rem;
  }
}

.field__checkbox,
.field__radio {
  flex-shrink: 0;
  position: relative;
}

.field__checkbox-input,
.field__radio-input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;

  &:focus {
    outline: 0;
  }
}

.field__checkbox-icon {
  border-radius: 0.3rem;
  box-shadow: inset 0 0 0 0.2rem $color-black;
  color: transparent;
  cursor: pointer;
  font-size: 1.6rem;
  height: 2.4rem;
  line-height: 0;
  padding: 0.4rem;
  transition-property: background-color, color;
  user-select: none;
  width: 2.4rem;

  .field__checkbox-input:checked ~ & {
    background-color: $color-black;
    color: $color-white;
  }
}

.field__radio-icon {
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.2rem $color-black;
  cursor: pointer;
  font-size: 1.4rem;
  height: 2.4rem;
  line-height: 0;
  padding: 0.5rem;
  position: relative;
  user-select: none;
  width: 2.4rem;

  &::after {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    height: 1.4rem;
    left: 0.5rem;
    opacity: 0;
    position: absolute;
    top: 0.5rem;
    transition-property: opacity;
    width: 1.4rem;
  }

  .field__radio-input:checked ~ &::after {
    opacity: 1;
  }
}

.field__line-label {
  --underline-focus-color: #{$text-color};

  align-self: center;
  flex-grow: 2;
  font-size: 1.6rem;
  line-height: 1.6;
  margin-left: 1.6rem;
}

.field__extra-content {
  margin-top: 1rem;
}

.field__error,
.field__description {
  display: flex;
  font-size: 1.6rem;
  margin-top: 1.6rem;
}

.field__error {
  color: $color-red;
  font-weight: bold;
}

.field__description-icon,
.field__error-icon {
  flex-shrink: 0;
  margin-right: 0.8rem;
}
