.quote-with-person{

  display: grid;
  grid-template-columns: 1fr 380px;
  width: 100%;

  @include use-responsive-sizing(display, (
    xs:block,
    m:grid,
  ));
  align-items: end;


  &--green{
    --quote-container-color: #{$color-sand};
    --person-color: #{$color-sand};
    --person-bg-color: #{$color-green};
    --quote-color: #{$color-green};

    .teaser__button{
      --button-background-color: #{$color-white};
      --button-color: #{$color-green};
      --button-focus-background-color: #{$color-green};
      --button-focus-color: #{$color-white};
    }
  }

  &--sand{
    --quote-container-color: #{$color-sand};
    --person-color: #{$color-green};
    --person-bg-color: #{$color-sand};
    --quote-color: #{$color-green};

    .teaser__button{
      --button-background-color: #{$color-green};
      --button-color: #{$color-white};
      --button-focus-background-color: #{$color-sand};
      --button-focus-color: #{$color-green};
    }
  }

  &--green-alt-1{
    --quote-container-color: #{$color-sand};
    --person-color: #{$color-white};
    --person-bg-color: #{$color-green-alt-1};
    --quote-color: #{$color-green};

    .teaser__button{
      --button-background-color: #{$color-white};
      --button-color: #{$color-green-alt-1};
      --button-focus-background-color: #{$color-green-alt-1};
      --button-focus-color: #{$color-white};
    }
  }

  &--green-alt-2{
    --quote-container-color: #{$color-sand};
    --person-color: #{$color-green};
    --person-bg-color: #{$color-green-alt-2};
    --quote-color: #{$color-green};

    .teaser__button{
      --button-background-color: #{$color-green};
      --button-color: #{$color-white};
      --button-focus-background-color: #{$color-green-alt-2};
      --button-focus-color: #{$color-green};
    }
  }

  &--blue{
    --quote-container-color: #{$color-sand};
    --person-color: #{$color-sand};
    --person-bg-color: #{$color-blue};
    --quote-color: #{$color-green};

    .teaser__button{
      --button-background-color: #{$color-white};
      --button-color: #{$color-blue};
      --button-focus-background-color: #{$color-blue};
      --button-focus-color: #{$color-white};
    }
  }


  &--yellow{
    --quote-container-color: #{$color-sand};
    --person-color: #{$color-green};
    --person-bg-color: #{$color-yellow};
    --quote-color: #{$color-green};

    .teaser__button{
      --button-background-color: #{$color-green};
      --button-color: #{$color-white};
      --button-focus-background-color: #{$color-yellow};
      --button-focus-color: #{$color-green};
    }

  }

  background: var(--quote-container-color);
  border-radius: 1rem;
  overflow:hidden;


  .person{
    position: relative;
    height:100%;
    margin-top:0;
    background: var(--person-bg-color);
    @include use-responsive-sizing(order, (
      xs: 2,
      m: 1,
    ));

    @include use-responsive-sizing(padding, (
      xs: 0 0 0.675rem,
      m: 0,
    ));

  }
  .person__image {
    margin-bottom: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    .image {
      width: 100%;
      .image__img{
        display: block;
        width: 100%;
      }
    }
  }

  .person__info{
    color: var(--person-color);
    @include use-responsive-sizing(padding, (
      xs: 2rem 2.4rem 0.4rem,
      m: 3.2rem 3.2rem 0.8rem,
    ));

    .headline{
      color: var(--person-color);
    }
    .teaser__description{
      margin-top:0.25rem;
      @include use-responsive-sizing(font-size, (
        xs: 1.6rem,
        m: 2rem,
      ));
    }
  }

  .quote {
    padding:3.2rem;
    @include use-responsive-sizing(padding, (
      xs: 2.4rem,
      m: 3.2rem,
    ));
    min-height:100%;
    &__quote{
      color: var(--quote-color);
      font-style: italic;
      font-weight: bold;
    }
  }

}
