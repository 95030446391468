.fui-i {
  --fui-table-add-btn-top: 1.3rem;
  --fui-label-font-size: 1.8rem;
  --fui-label-font-weight: 600;
  --fui-font: #{$font-stack-body};
  --fui-font-size: 1.8rem;
  --fui-color: #{$text-color};
  --fui-label-color: #{$color-gray};
  --fui-primary-color: #{$color-green-alt-1};
  --fui-focus-shadow: 0 0 0 3px #{$color-light-green};
  --fui-input-box-shadow-focus: 0 0 0 2px #{$color-green};
  --fui-focus-border-color: #{$color-green};
  --fui-label-margin: 1.2rem;
  --fui-check-margin-right: 0;
  --fui-check-margin-bottom: 0;
  --fui-check-font-size: 1.6rem;
  --fui-check-line-height: 1.6;
  --fui-row-gutter: 2.4rem;
  --fui-check-label-width: 2.4rem;
  --fui-check-label-height: 2.4rem;
  --fui-check-label-top: 0;
  --fui-check-label-border: 0.2rem solid #{$text-color};
  --fui-check-label-padding-left: 4rem;
  --fui-check-label-line-height: 2.4rem;
  --fui-check-radio-bg-size: 1.2rem;
  --fui-input-font-size: 1.8rem;
  --fui-input-line-height: 3rem;
  --fui-input-padding: 1.2rem 1.6rem;
  --fui-border-radius: 0.5rem;
  --fui-error: #{$color-pink};
  --fui-submit-btn-color: #{$color-white};
  --fui-submit-btn-bg-color: #{$color-green};
  --fui-submit-btn-border-color: #{$color-green};
  --fui-submit-btn-color-hover: #{$color-green};
  --fui-submit-btn-bg-color-hover: #{$color-white};
  --fui-submit-btn-border-color-hover: #{$color-green};
  --fui-btn-font-size: 1.6rem;
  --fui-btn-line-height: 1;
  --fui-btn-padding: 1.1rem 1.8rem;
  --fui-table-add-btn-padding-left:2.6rem;
  --fui-btn-border: 2px solid #{$color-green};
  --fui-btn-border-radius: 2.1rem;
  --fui-btn-font-weight: bold;
  --fui-alert-error-bg-color: #{scale-color($color-red, $lightness: 95%)};
  --fui-alert-error-color: #{$color-red};
  --fui-alert-padding: 2rem;
  --fui-alert-line-height: 1.6;
  --fui-alert-font-size: 1.6rem;
  --fui-alert-margin-bottom: 2.4rem;
  --fui-label-error-color: #{$color-red};
  --fui-error-font-size: 1.4rem;
  --fui-error-margin-top: 1.2rem;
  --fui-alert-border-radius: 0;
  --fui-instructions-margin: 1.5rem;
  --fui-instructions-font-size: 1.6rem;
  --fui-instructions-line-height: 1.6;
  --fui-instructions-color: #{$color-gray};
  --fui-check-check-bg-size: 1.2rem;
  --fui-table-th-font-size: 1.6rem;


  @include mq($from: m) {
    --fui-btn-font-size: 1.8rem;
    --fui-btn-padding: 1.2rem 2.4rem;
  }
}

.fui-heading {
  @include use-responsive-sizing(font-size, responsive-map(xs 3.2rem, xl 5.6rem));

  color: var(--headline-color, currentColor);
  font-family: $font-stack-headline;
  font-weight: bold;
  line-height: $line-height-headline;
}

.fui-layout-wrap {
  flex-wrap: wrap;
}

.fui-radio {
  width: 100%;

  & + & {
    margin-top: 0.8rem;
  }
}
